import { Component, Fragment } from 'react';
import { Modal } from 'antd';
import { formats } from '../../constants/aveSupportedFormats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default class PopupWindow extends Component {
    renderColumn = (title, list) => {
        const { fillClassName } = this.props;

        const listItems = list.map((format, index) => (
            <li key={index}>
                <span className="fa-li"><FontAwesomeIcon className={`${fillClassName}-embed-title fas fa-chevron-right`} icon={faChevronRight} /></span>{format}
            </li>
        ));

        return (
            <div className="col-md-6 mb-4">
                <h4 className="mb-4 lead lead-small medium">
                    {title ? title : <br />}
                </h4>
                <div className="">
                    <ul className="fa-ul">
                        {listItems}
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Modal
                width="90%"
                className={`popup-modal ${this.props.fillClassName}`}
                title={<div><h4 className="pr-4"><img width="" height="" alt="" className="modal-logo" src="/images/logo.svg"/> Supported File Formats</h4></div>}
                centered={true}
                onCancel={this.props.hidePopup}
                visible={this.props.popupIsVisible}
                footer={null}
            >
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <ul className="nav nav-tabs nav-fill text-center">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link text-center ${this.props.firstTabIsActive ? "active" : ""} `}
                                        onClick={() => this.props.setActiveTab(0)} >
                                        Document Formats
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link text-center ${this.props.firstTabIsActive ? "" : "active"}`}
                                        onClick={() => this.props.setActiveTab(1)} >
                                        RAW Camera Image Formats
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div id="content" className="tab-content py-5 px-4">
                                <div className="tab-pane active">
                                  {
                                    this.getActiveTab()
                                  }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    getActiveTab = () => {
      if (this.props.firstTabIsActive) {
        return (
            <Fragment>
              <div className="row">
                {this.renderColumn("OpenXML", formats.openXmlFormats)}
                {this.renderColumn("OpenDocument Format", formats.openDocumentFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Text", formats.textFormats)}
                  {this.renderColumn("PDF", formats.pdfFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Vector Images", formats.vectorImagesFormats)}
                    {this.renderColumn("CAD", formats.cadFormats)}
                    {this.renderColumn("EMAIL", formats.emailFormats)}
              </div>
              <div className="row">
                {this.renderColumn("Raster Images", formats.rasterImagesFormatsFirstPart)}
                {this.renderColumn("", formats.rasterImagesFormatsSecondPart)}
              </div>
            </Fragment>
        );
      } else {
        return (
            <Fragment>
              <div className="row">
                  {this.renderColumn("Hasselblad", formats.hasselbladFormats)}
                  {this.renderColumn("Casio", formats.casioFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("NuCore", formats.nucoreFormats)}
                  {this.renderColumn("Leaf", formats.leafFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Sony", formats.sonyFormats)}
                  {this.renderColumn("Phase", formats.phaseFormats)}
              </div>
              <div className="row">
                {this.renderColumn("Phantom", formats.phantomFormats)}
                {this.renderColumn("Apple", formats.appleFormats)}
              </div>
              <div className="row">
                {this.renderColumn("Canon", formats.canonFormats)}
                {this.renderColumn("Minolta", formats.minoltaFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Capture", formats.captureFormats)}
                  {this.renderColumn("Adobe", formats.adobeFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Epson", formats.epsonFormats)}
                  {this.renderColumn("Imacon", formats.imaconFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Sinar", formats.sinarFormats)}
                  {this.renderColumn("Digital", formats.digitalFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Mamiya", formats.mamiyaFormats)}
                  {this.renderColumn("Nikon", formats.nikonFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Fuji", formats.fujiFormats)}
                  {this.renderColumn("Olympus", formats.olympusFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Pentax", formats.pentaxFormats)}
                  {this.renderColumn("Panasonic", formats.panasonicFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Logitech", formats.logitechFormats)}
                  {this.renderColumn("Rawzor", formats.rawzorFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Leica", formats.leicaFormats)}
                  {this.renderColumn("Samsung", formats.samsungFormats)}
              </div>
              <div className="row">
                  {this.renderColumn("Kodac", formats.kodacFormatsFirstPart)}
                  {this.renderColumn("", formats.kodacFormatsSecondPart)}
              </div>
            </Fragment>
        );
      }
    }
}