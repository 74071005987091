import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <div>{i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}<br/>
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}
    {renderBlock(i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/briefly-about-tiff/' })}
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/' })}
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
    {renderBlock(i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/lzw-three-graphics-file-formats/' })}
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}<br/>
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
        {renderBlock(i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'AvePDFLink', href: tools.pdfToJpg.url })}
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}<br/>
        {renderBlock(i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1, { type: 'AvePDFLink', href: tools.pngToPdf.url })}
    {i10n._VIEW_PDFTOTIFF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}</div>
