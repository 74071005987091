import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumnWithAddedLink = () =>
    <div>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/lzw-three-graphics-file-formats/'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'https://mike.pub/19950127-gif-lzw'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}<br/>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1, { type: 'link', href: 'http://www.libpng.org/pub/png/spec/1.2/PNG-Contents.html'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}</div>


export const renderSecondColumnWithAddedLink = () =>
    <div>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}<br/>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}</div>


export const renderThirdColumnWithAddedLink = () =>
    <div>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/briefly-about-png/'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}{renderBlock(i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1, { type: 'link', href: 'https://www.w3.org/TR/REC-png-multi.html'})}{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}<br/>{i10n._VIEW_PNGTOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}</div>