const USER = 'user';
const SCANNER = 'scanner';
const HAS_SHARED_ON_SOCIAL = 'hasAlreadyShareWebsite';
const THEME = 'theme';
const LANG = 'language';
const PREVIOUS_CONTEXT = 'previous_file_context';
const OCR_LANG = 'ocr_lang';
const USAGE = 'usage';
const REDIRECT_TO = 'redirect';
export default {
    USER,
    SCANNER,
    HAS_SHARED_ON_SOCIAL,
    THEME,
    LANG,
    PREVIOUS_CONTEXT,
    OCR_LANG,
    USAGE,
    REDIRECT_TO
};