import axios from 'axios';
import { getDefaultHeaders } from '../defaultHeaders'
import sitemap from '../sitemap'
import { GetUrl } from './utilities';

class _FileService {
    constructor() {
        this.cancelTokenSource = axios.CancelToken.source();
    }

    cancelRequest = () => {
        this.cancelTokenSource.cancel();
        this.cancelTokenSource = null;
        this.cancelTokenSource = axios.CancelToken.source();
    }

    _post = (url, data, timeout) => {
        var _this = this;
        var timeout = (typeof x === 'undefined') ? 0 : timeout;

        return axios.post(
            `${url}`,
            data,
            {
                timeout: timeout,
                headers: getDefaultHeaders(),
                cancelToken: _this.cancelTokenSource.token
            }
        );
    }

    _get = (url, timeout) => {
        var _this = this;
        var timeout = (typeof x === 'undefined') ? 0 : timeout;

        return axios.get(
            `${url}`,
            {
                timeout: timeout,
                headers: getDefaultHeaders(),
                cancelToken: _this.cancelTokenSource.token
            }
        );
    }

    prepareFileForTool = (params) => {
        return this._post(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.PREPARE_FILE_FOR_TOOL_ROUTE]), params);
    }

    /**
     * @returns AxiosResponse
     */
    loadFromHttp = (params) => {
        return this._post(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.LOAD_FROM_HTTP]), params);
    }

    redirectOutputFile = (processedContextId, fileToRedirect) => {
        return this._post(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.REDIRECT_OUTPUT_FILE_ROUTE, processedContextId, fileToRedirect]), {});
    }

    download = (id, onDownloadProgress) => {
        return axios.get(
            GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.DOWNLOAD_CLIENT_ROUTE, id]),
            {
                responseType: 'blob',

            }
        );
    }

    downloadPreview = (processedContextId, fileId, onDownloadProgress) => {
        return axios.get(
            GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.DOWNLOAD_PREVIEW_ROUTE, processedContextId + "?fileId=" + fileId]),
            {
                responseType: 'blob',
                onDownloadProgress: onDownloadProgress,
                data: {
                    processedContextId: processedContextId,
                    fileId: fileId
                }
            }
        );
    }

    removeFile = (id, removeInput, removePendingFiles, removeOutput) => {
        return this._post(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.REMOVE_FILE_CLIENT_ROUTE, id, removeInput, removePendingFiles, removeOutput]), null);
    }

    uploadFile = (formData, headers, onUploadProgress) => {
        return axios.post(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.UPLOAD_ROUTE]), formData, {
            cancelToken: this.cancelTokenSource.token,
            headers: {
                "Content-Type": "multipart/form-data",
                ...headers
            },
            onUploadProgress: onUploadProgress,

        })
    }

    getUsage = () => {
        return axios.get(GetUrl([sitemap.FILES_CONTROLLER_ROUTE, sitemap.FILE_USAGE ]))
    }
};

const FileService = new _FileService();
export default FileService;