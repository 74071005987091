import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import { createBrowserHistory  } from 'history';
import toolLayoutReducer from './common/reducers/toolLayoutReducer';
import uploadFileReducer, { _initialState as fileReducerInitialState } from './common/reducers/uploadFileReducer';
import { hasLocalStorageEnable, saveItem } from "./common/helpers/localeStorage"
import LocalStorageKeys from "./constants/localStorageKeys";

export const history = createBrowserHistory();

const initialState = {};

if (hasLocalStorageEnable()) {
    let previousContextString = window.localStorage.getItem(LocalStorageKeys.PREVIOUS_CONTEXT);
    if (previousContextString !== null){
        let previousContext = JSON.parse(previousContextString);
        let now = new Date();
        previousContext = previousContext.filter(pc => new Date(pc.expiresAt) > now);
        initialState.files = {
            ...fileReducerInitialState,
            processedContext: previousContext
        }

        //update locale storage to remove expired pc
        saveItem(LocalStorageKeys.PREVIOUS_CONTEXT, previousContext);
    }
}

const enhancers = [];
const middleware = [
    routerMiddleware(history),
    thunk
];

if (process.env.NODE_ENV !== 'production' && typeof window != 'undefined') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ({  serialize: true, trace: true });

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const reducers = (history) => {
    return combineReducers({
        router: connectRouter(history),
        tool: toolLayoutReducer,
        files: uploadFileReducer,
    })
}

export const store = createStore(
        reducers(history),
        initialState,
        composedEnhancers,
    );