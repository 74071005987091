export const formats = { 

    openXmlFormats : [
        "DOCX – Microsoft Word OpenXML",
        "XLSX – Microsoft Excel OpenXML",
        "PPTX – Microsoft PowerPoint OpenXML",
    ],

    openDocumentFormats : [
        "ODT – OpenDocument Text",
    ],

    textFormats : [
        "TXT – plain text file",
        "RTF – Rich Text File",
    ],

    pdfFormats : [
        "PDF – Portable Document Format",
        "PDF/A – Document Format for long term preservation",
        "PDF/UA – Document Format for universal accessibility",
        "PDF Multi-page – Portable Document Format",
    ],

    vectorImagesFormats : [
        "SVG – Scalable Vector Graphics",
        "EMF – Enhanced Windows Meta-format",
        "WMF – Standard Windows Meta-format",
    ],

    cadFormats : [
        "DXF – Drawing eXchange Format",
    ],

    emailFormats: [
        "MSG – Microsoft Outlook Item",
        "EML – Electronic Mail Format",
    ],

    rasterImagesFormatsFirstPart : [
        "CUR – Windows Cursor",
        "WSQ – Wavelet scalar quantization",
        "ANIMATED GIF – Graphics Interchange Format",
        "BMP – Standard Windows Bitmap Format",
        "CUT – Dr. Halo/Dr.Genius Clipboard Format",
        "DDS – Microsoft Direct-draw Surface Format",
        "DIB – Standard Windows Bitmap Format",
        "DICOM – Digital Imaging and Communications in Medicine",
        "EXIF – Exchangeable Image Format",
        "EXR – OpenEXR Format",
        "FAX, G3 – Group 3 Raw Fax Format",
        "GIF, Interlaced GIF – Graphics Interchange Format",
        "HDR – High Dynamic Range Format",
        "HEIF / HEIC – High Efficiency Image File Format",
        "IFF – Interchange Format ",
        "ICO (single page and multi page) – Icon Format",
        "J2K, J2C – JPEG-2000 Code-stream ",
        "JB2, JBIG2 – Joint Bi-level Image Experts Group",
        "JIF, JFIF – JPEG File Interchange Format",
        "JNG – JPEG Network Graphics",
        "JP2 – JPEG-2000 Format",
        "JPEG, JPG, JPE – Joint Photographic Expert Group",
        "JPEG progressive",
        "KOA – KOALA Format",
        "LBM – Interchange File Format-Interleaved Bitmap"
    ],

    rasterImagesFormatsSecondPart : [
        "MNG – Multiple-image Network Graphics *",
        "PBM – Portable Bitmap File",
        "PBM RAW – Portable Bitmap BINARY",
        "PCD – Kodak Photo-CD file",
        "PCT, PICT, PIC – Macintosh PICT Format",
        "PCX – PC Paintbrush Format",
        "PFM – Portable Float Map",
        "PGM – Portable Gray-map File",
        "PGM RAW – Portable Gray-map BINARY",
        "PSD – Photoshop File",
        "PNG – Portable Network Graphics Format",
        "PNM – Portable Any Map",
        "PPM – Portable Pix-map File",
        "PM RAW – Portable Pix-map BINARY",
        "RAS – Sun Raster Format",
        "RAW camera image",
        "RAW memory bits – RAW bitmap",
        "RLE – Standard Windows Bitmap format",
        "SGI – Silicon Graphics Image Format",
        "TGA, TARGA – TARGA Image Format",
        "TIFF, TIF – Tagged Image Format",
        "TIFF Multi-page – Multi-page Tagged Image Format",
        "WBMP, WAP, WBM – Wireless Bitmap",
        "XBM – X-Bitmap Format",
        "XPM – X Pixmap Format",
    ],

    hasselbladFormats : [
        "3fr – Hasselblad Digital Camera Raw Image Format",
    ],

    casioFormats : [
        "bay – Casio Digital Camera Raw File Format",
    ],

    nucoreFormats : [
        "bmq – NuCore Raw Image File",
    ],

    leafFormats : [
        "hdr – Leaf Raw Image File",
    ],

    sonyFormats : [
        "arw – Sony Digital Camera Raw Image Format for Alpha devices",
        "sr2 – Sony Digital Camera Raw Image Format",
        "srf – Sony Digital Camera Raw Image Format for DSC-F828 8 megapixel digital camera or Sony DSC-R1",
    ],

    phaseFormats : [
        "cap – Phase One Digital Camera Raw Image Format",
        "iiq – Phase One Digital Camera Raw Image Format",
    ],

    phantomFormats : [
        "cine – Phantom Software Raw Image File",
    ],

    appleFormats : [
        "qtk – Apple Quicktake 100/150 Digital Camera Raw Image Format",
    ],

    canonFormats : [
        "cr2 – Canon Digital Camera RAW Image Format version 2.0. These images are based on the TIFF image standard",
        "crw – Canon Digital Camera RAW Image Format version 1.0",
    ],

    minoltaFormats : [
        "mdc – Minolta RD175 Digital Camera Raw Image Format",
        "mrw – Minolta Dimage Digital Camera Raw Image Format",
    ],

    captureFormats : [
        "cs1 – Capture Shop Raw Image File",
    ],

    adobeFormats : [
        "dng – Adobe Digital Negative: DNG is a publicly-available, archival format for the raw files generated by digital cameras. By addressing the lack of an open standard for the raw files created by individual camera models, DNG helps ensure that photographers will be able to access their files in the future",
    ],

    epsonFormats : [
        "erf – Epson Digital Camera Raw Image Format",
    ],

    imaconFormats : [
        "fff – Imacon Digital Camera Raw Image Format",
    ],

    sinarFormats : [
        "ia – Sinar Raw Image File",
        "sti – Sinar Capture Shop Raw Image File",
    ],

    digitalFormats : [
        "rdc – Digital Foto Maker Raw Image File",
    ],

    mamiyaFormats : [
        "mef – Mamiya Digital Camera Raw Image Format",
        "mos – Mamiya Digital Camera Raw Image Format",
    ],

    nikonFormats : [
        "nef – Nikon Digital Camera Raw Image Format",
        "nrw – Nikon Digital Camera Raw Image Format",
    ],

    fujiFormats : [
        "raf – Fuji Digital Camera Raw Image Format",
    ],

    olympusFormats : [
        "orf – Olympus Digital Camera Raw Image Format",
    ],

    pentaxFormats : [
        "pef – Pentax Digital Camera Raw Image Format",
        "ptx – Pentax Digital Camera Raw Image Format",
    ],

    panasonicFormats : [
        "raw – Panasonic Digital Camera Image Format",
        "rw2 – Panasonic LX3 Digital Camera Raw Image Format",
    ],

    logitechFormats : [
        "pxn – Logitech Digital Camera Raw Image Format",
    ],

    rawzorFormats : [
        "rwz – Rawzor Digital Camera Raw Image Format",
    ],

    leicaFormats : [
        "rw1 – Leica Camera Raw Image Format",
    ],

    samsungFormats : [
        "srw – Samsung Raw Image Format",
    ],

    kodacFormatsFirstPart : [
        "dc2 – Kodak DC25 Digital Camera File",
        "dcr – Kodak Digital Camera Raw Image Format for these models: Kodak DSC Pro SLR/c, Kodak DSC Pro SLR/n, Kodak DSC Pro 14N, Kodak DSC PRO 14nx",
        "drf – Kodak Digital Camera Raw Image Format",
        "dsc – Kodak Digital Camera Raw Image Format",
    ],

    kodacFormatsSecondPart : [
        "kc2 – Kodak DCS200 Digital Camera Raw Image Format",
        "k25 – Kodak DC25 Digital Camera Raw Image Format",
        "kdc – Kodak Digital Camera Raw Image Format",
    ],
};
