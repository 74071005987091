import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <div>{i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}<br/>
    {renderBlock(i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/linearized-pdf/' })}
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}</div>


export const renderSecondColumn = () =>
    <div>{i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}
    {renderBlock(i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1, { type: 'link', href: 'https://opensource.adobe.com/dc-acrobat-sdk-docs/standards/pdfstandards/pdf/PDF32000_2008.pdf' })}
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}<br/></div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}<br/>
    {i10n._VIEW_LINEARIZEPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}</div>