import React, { PureComponent } from 'react';
import { goTop, ToolStatus } from './tool';
import allTools, { toolCategories, toolCategoriesLabel } from '../../constants/tools';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AsyncSVG } from './AsyncSVG';
import i10n from '../i10n';
import {faSquareArrowUpRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class MegaMenuComponent extends PureComponent {

    render() {
        const excludedCategory = [toolCategories.popular, toolCategories.other];

        let tools = Object.keys(allTools)
            .map(toolKey => allTools[toolKey])
            .filter(tool => tool.status !== ToolStatus.Hidden && tool.status !== ToolStatus.ComingSoon)
            .map(t => {     //Select most relevant category in the category array of each tool
                let cat = null;
                t.category.forEach(c => {
                    if (!excludedCategory.includes(c)) {
                        cat = c;
                    }
                });

                return { 
                    tool: { 
                        isCurrentTool: this.props.currentPageId.split(',').includes(t.id), 
                        ...t 
                    }, 
                    category: cat 
                };
            })
            .filter(t => t.category !== null) //where category is not null
            .reduce(  // Group by category
                (entryMap, e) => entryMap.set(e.category, [...entryMap.get(e.category) || [], e]),
                new Map()
            );

        tools = new Map([...tools.entries()].sort()); //order by category number

        const markup = [];
        tools.forEach((toolsCat, cat) => {
            markup.push(
                <>
                    <div className="megamenu-tools-category">
                        <div className="w-100">
                            <p className="megamenu-category-title">{Object.values(toolCategoriesLabel)[cat]}</p>
                        </div>
                        <div className="w-100">
                            <ul className="megamenu-list-category">
                                {
                                    toolsCat.map(tc => tc.tool).sort((a, b) => a.title()?.localeCompare(b.title())).map(tool => {
                                        var badge = <>
                                            {
                                                tool.status === ToolStatus.Beta &&
                                                <div className="tool-label">
                                                    <p>{i10n._VIEW_LAYOUT_BETA_BADGE}</p>
                                                </div>
                                            }
                                        </>
                                        const linkComponent = tool.url().startsWith('http') ?
                                            <a href={tool.url()} target="_blank">{tool.title()}{badge}<FontAwesomeIcon className="ml-2" icon={faSquareArrowUpRight} /></a> :
                                            <Link to={tool.url()}>{tool.title()}{badge}</Link>;
                                        return (
                                            <li key={tool.id} className={`${tool.isCurrentTool ? "active" : ""} ${tool.status === ToolStatus.Beta ? "beta-tool" : ""}`}>
                                                {
                                                    this.props.showIcon &&
                                                    <div className={`tool-item-block-icon ${tool.fillClassName}`} >
                                                        <AsyncSVG src={tool.image} />
                                                    </div>
                                                }
                                                
                                                {linkComponent}

                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </>)
        })

        return (
            <div className="container p-4">
                {
                    markup.map(t => t)
                }
            </div >);

    }
}

const mapState = ({ tool, files }) => {
    return {
    };
}

const mapDispatch = (dispatch) => {
    return {
    };
}

const MegaMenu = connect(mapState, mapDispatch)(MegaMenuComponent);
export default MegaMenu;