import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools  from '../../../constants/tools';
export const renderFirstColumn = () =>
    <>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_1}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_2}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_3}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_4}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_2, { type: 'link', href: 'https://blog.avepdf.com/three-free-tools-secure-pdf-files/' })}
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_3}
        {renderBlock(i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_4, { type: 'link', href: tools.protect.url(), removeSpaceAfter: true })},
        {renderBlock(i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_5, { type: 'link', href: tools.esign.url(), removeSpaceAfter: true })},
        {renderBlock(i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_6, { type: 'link', href: tools.convertA.url() })}
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_1_DESC_5_PART_7}
    </>

export const renderSecondColumn = () =>
    <>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_1}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_2}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_3}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_4}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_5}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_2_DESC_6}
    </>

export const renderThirdColumn = () =>
    <>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_3_DESC_1}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_3_DESC_2}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_3_DESC_3}<br/>
        {i10n._VIEW_WATERMARK_DIDYOUKNOW_COLUMN_3_DESC_4}
    </>
