import { Link } from 'react-router-dom';
import { goTop } from '../components/tool';
import i10n from '../i10n';

export const getColumnList = function (keyPart, iconFillClassName) {
    var columns = [];
    var columnNumber = 1;

    while (true) {
        var column = {
            title: '',
            text: [],
        };

        column.title = i10n[`_VIEW_${keyPart}_DIDYOUKNOW_COLUMN_${columnNumber}_TITLE`];

        column.text = getDescriptionForColumn(keyPart, columnNumber);

        if (column.title) {
            columns.push(column);
            ++columnNumber;
        } else {
            break;
        }
    }
    return columns;
}

export const renderBlock = (text, modification) => {
    if (modification) {
        switch (modification.type) {
            case "bold":
                return <span className="ave-tooltip-title"> {text}</span>;
            case "link":
                return <a className="did-you-know-href" href={modification.href}>{modification.removeSpaceBefore === true ? "" : " "}{text}{modification.removeSpaceAfter === true ? "" : " "}</a>;
            case "AvePDFLink":
                return <Link onClick={goTop} className="did-you-know-href" to={modification.href}>{modification.removeSpaceBefore === true ? "" : " "}{text}{modification.removeSpaceAfter === true ? "" : " "}</Link>;
        }
    }
    return <span>{text} </span>;
}

const getDescriptionForColumn = (keyPart, columnNumber) => {
    let description = []
    let descNumber = 1;

    while (true) {
        let text = getDescriptionParts(keyPart, columnNumber, descNumber);

        if (text.length > 0) {
            description.push(text);
            ++descNumber;
        } else {
            break;
        }
    }
    return description;
}

const getDescriptionParts = (keyPart, columnNumber, descNumber) => {
    let parts = [];
    let partCount = 1;

    while (true) {
        const text = i10n[`_VIEW_${keyPart}_DIDYOUKNOW_COLUMN_${columnNumber}_DESC_${descNumber}_PART_${partCount}`];
        if (text) {
            parts.push(text);
            ++partCount;
        } else {
            break;
        }
    }
    return parts;
}