let isEnabled = null


export const hasLocalStorageEnable = () => {
    if (isEnabled === null) {
        try {
            var storage = window['localStorage'],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            isEnabled = true;
        }
        catch(e) {
            isEnabled = false;
        }
    }
    return isEnabled;
}

export const saveItem = (key, item) => {
    if (hasLocalStorageEnable()) {
        window.localStorage.setItem(key, typeof item === 'string' ? item : JSON.stringify(item));
    }
}