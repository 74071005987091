window.avePdfLog = console.log;

if (process.env.NODE_ENV != 'development') {
    console.log = function() {};
}

export function log(message, showInProd) {
    if (showInProd || process.env.NODE_ENV === 'development') {
        window.avePdfLog(message);
    }
}