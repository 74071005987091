import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <div>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}<br/>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}{renderBlock(i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1, { type: 'link', href: 'https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679&from=EN' })}{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}{renderBlock(i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/California_Consumer_Privacy_Act' })}{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}<br/>{i10n._VIEW_REDACTPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}</div>
