import moment from 'moment';

export default {
    currentLang: window.AvePDFAppState.CurrentLanguage,
    async init() {
        if (!this.currentLang)
            throw 'missing lang';
            try{
                let labels = await import(`./i10n.${this.currentLang}.json`);
                Object.assign(this, labels);
                //locale time
                if (this.currentLang !== "en"){
                    try{
                        const locale = await import(`moment/locale/${this.currentLang}`)
                        moment.updateLocale(this.currentLang, locale);
                        moment.locale(this.currentLang);
                    }
                    catch {
                        //lang not available
                    }
                }
            }
            catch(err){
                throw err;
            }
    },
    formatDate(value, format) {
        return moment(value).format(format);
    },
    getBestLang() {
        var navigatorLangs = window.navigator.languages.map(p => {
            if (p.length > 2) {
                return p.substr(0, 2);
            }
            return p;
        });
        var availableLangs = this.getAvailableLanguages().map(p => p.value);
        var bestlang = "";
        for (let index = 0; index < navigatorLangs.length; index++) {
            const navigatorLang = navigatorLangs[index];
            if (availableLangs.includes(navigatorLang)) {
                bestlang = navigatorLang;
                break;
            }
        }
        return bestlang;
    },
    getToolLabels(tool) {
        if (typeof tool === 'undefined' || typeof tool.howToUseLocalizationKeyPart === 'undefined')
            return null;

        return {
            homeTitle: this[`_VIEW_${tool.howToUseLocalizationKeyPart}_TITLE_HOMEPAGE`],
            homeSubTitle: this[`_VIEW_${tool.howToUseLocalizationKeyPart}_SUBTITLE_HOMEPAGE`],
            title: tool.title(),
            subtitle: tool.subtitle(),
            loading: tool.loadingSubTitle()
        };
    },
    getAvailableLanguages() {
        return [
            {
                "text": "Čeština",
                "value": "cs"
            },
            {
                "text": "Dansk",
                "value": "da"
            },
            {
                "text": "Deutsch",
                "value": "de"
            },
            {
                "text": "English",
                "value": "en"
            },
            {
                "text": "Español",
                "value": "es"
            },
            {
                "text": "Français",
                "value": "fr"
            },
            {
                "text": "Indonesia",
                "value": "id"
            },
            {
                "text": "Italiano",
                "value": "it"
            },
            {
                "text": "Magyar",
                "value": "hu"
            },
            {
                "text": "Nederlands",
                "value": "nl"
            },
            {
                "text": "Norsk",
                "value": "no"
            },
            {
                "text": "Polski",
                "value": "pl"
            },
            {
                "text": "Português",
                "value": "pt"
            },
            {
                "text": "Română",
                "value": "ro"
            },
            {
                "text": "Slovenčina",
                "value": "sk"
            },
            {
                "text": "Slovenščina",
                "value": "sl"
            },
            {
                "text": "Suomi",
                "value": "fi"
            },
            {
                "text": "Svenska",
                "value": "sv"
            },
            {
                "text": "Tiếng Việt",
                "value": "vi"
            },
            {
                "text": "Türkçe",
                "value": "tr"
            },
            {
                "text": "Ελληνικά",
                "value": "el"
            },
            {
                "text": "Български",
                "value": "bg"
            },
            {
                "text": "Українська",
                "value": "uk"
            },
            {
                "text": "Русский",
                "value": "ru"
            },
            {
                "text": "עברית",
                "value": "he"
            },
            {
                "text": "العربية",
                "value": "ar"
            },
            {
                "text": "हिंदी",
                "value": "hi"
            },
            {
                "text": "ไทย",
                "value": "th"
            },
            {
                "text": "한국어",
                "value": "ko"
            },
            {
                "text": "中文",
                "value": "zh"
            },
            {
                "text": "日本語",
                "value": "ja"
            }];
    },
}


