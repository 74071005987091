import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools  from '../../../constants/tools';
export const renderFirstColumn = () =>
    <>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_1}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}
        {renderBlock(i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_2, { type: 'AvePDFLink', href: tools.convertA.url(), removeSpaceAfter: true })}
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_3}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_3}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}
        {renderBlock(i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_2, { type: 'AvePDFLink', href: tools.redactPdf.url(), removeSpaceAfter: false })}
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_3}<br />
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_2, { type: 'AvePDFLink', href: tools.watermark.url() })}
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_3}
        {renderBlock(i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_4, { type: 'AvePDFLink', href: tools.esign.url() })}
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_5}
    </>

export const renderSecondColumn = () =>
    <>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_1}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_2}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_3}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_4}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_5}
    </>

export const renderThirdColumn = () =>
    <>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_1}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_2}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_3}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_4}<br/>
        {i10n._VIEW_UNLOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_5}
    </>
