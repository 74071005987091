import { Carousel, Rate } from 'antd';
import React from 'react';
import i10n from '../i10n';
import { AsyncSVG } from './AsyncSVG';
import { Link } from 'react-router-dom';
import tools from '../../constants/tools';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload, faStar } from '@fortawesome/free-solid-svg-icons';

export class Tiles extends React.PureComponent {
    render = () => {
        return <>
            <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row justify-content-center text-center mt-5">
                        <div className="col-10 pt-5">
                            <h3 className="bold title-small mb-3">{i10n._VIEW_LAYOUT_TILES_HEADER_TITLE}</h3>
                            <p className="avepdf-bck-details-desc">
                                {i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_1}
                                <Link to={tools.hyperCompress.url()}> {i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_2}</Link>, <Link to={tools.ocr.url()}>{i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_3}</Link>, <Link to={tools.redactPdf.url()}>{i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_4}</Link>, <Link to={tools.convertA.url()}>{i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_8}</Link>, <Link to={tools.scanningToPdf.url()}>{i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_5}</Link> {i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_6} <Link to={tools.esign.url()}> {i10n._VIEW_LAYOUT_TILES_HEADER_CONTENT_PART_7}</Link>.
                            </p>
                            <div className="producthunt-badge mt-5">
                                <div className="ph-badge">
                                    <a href="https://www.producthunt.com/posts/avepdf?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-avepdf" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=394995&theme=neutral" alt="AvePDF - Free&#0032;Online&#0032;PDF&#0032;and&#0032;Document&#0032;Tools | Product Hunt" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="bold title-small mb-1">{i10n._VIEW_LAYOUT_TILES_SECURITY_TITLE}</h3>
                            <h4 className="title-xsmall mb-4">{i10n._VIEW_LAYOUT_TILES_SECURITY_SUBTITLE}</h4>
                            <p className="avepdf-bck-details-desc" dangerouslySetInnerHTML={{ __html: i10n._VIEW_LAYOUT_TILES_SECURITY_CONTENT }}></p>
                        </div>
                        <div className="col-md-6">
                            <div className="avepdf-bck-details-img">
                                <AsyncSVG src='/images/avepdf-security-visual.svg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row align-items-center flex-column-reverse flex-md-row">
                        <div className="col-md-6">
                            <div className="avepdf-bck-details-img">
                                <AsyncSVG src='/images/avepdf-save-time-visual.svg' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className="bold title-small mb-1">{i10n._VIEW_LAYOUT_TILES_TIME_TITLE}</h3>
                            <h4 className="title-xsmall mb-4">{i10n._VIEW_LAYOUT_TILES_TIME_SUBTITLE}</h4>
                            <p className="avepdf-bck-details-desc" dangerouslySetInnerHTML={{ __html: i10n._VIEW_LAYOUT_TILES_TIME_CONTENT }}></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="bold title-small mb-1">{i10n._VIEW_LAYOUT_TILES_OUR_TECH_TITLE}</h3>
                            <h4 className="title-xsmall mb-4">{i10n._VIEW_LAYOUT_TILES_OUR_TECH_SUBTITLE}</h4>
                            <p className="avepdf-bck-details-desc" dangerouslySetInnerHTML={{ __html: i10n._VIEW_LAYOUT_TILES_OUR_TECH_CONTENT }}></p>
                            <a href="https://www.orpalis.com/products/" target="_blank">{i10n._VIEW_LAYOUT_TILES_OUR_TECH_LEARN_MORE} <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                        </div>
                        <div className="col-md-6">
                            <div className="avepdf-bck-details-img">
                                <AsyncSVG src='/images/avepdf-technologies-visual.svg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row align-items-center flex-column-reverse flex-md-row">
                        <div className="col-md-6">
                            <div className="avepdf-bck-details-img">
                                <AsyncSVG src='/images/avepdf-innovation-visual.svg' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className="bold title-small mb-1">{i10n._VIEW_LAYOUT_TILES_INNOVATION_TITLE}</h3>
                            <h4 className="title-xsmall mb-4">{i10n._VIEW_LAYOUT_TILES_INNOVATION_SUBTITLE}</h4>
                            <p className="avepdf-bck-details-desc" dangerouslySetInnerHTML={{ __html: i10n._VIEW_LAYOUT_TILES_INNOVATION_CONTENT }}></p>
                            <a href="https://www.orpalis.com/company/#customers" target="_blank">{i10n._VIEW_LAYOUT_TILES_INNOVATION_MEET} <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="avepdf-bck-details">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className="bold title-small mb-1">{i10n._VIEW_LAYOUT_TILES_MEET_TITLE}</h3>
                            <h4 className="title-xsmall mb-4">{i10n._VIEW_LAYOUT_TILES_MEET_SUBTITLE}</h4>
                            <p className="avepdf-bck-details-desc">
                                {i10n._VIEW_LAYOUT_TILES_MEET_CONTENT}
                            </p>
                            <a href="https://www.orpalis.com/company/#team" target="_blank">{i10n._VIEW_LAYOUT_TILES_MEET_US} <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                        </div>
                        <div className="col-md-6">
                            <div className="avepdf-bck-details-img">
                                <AsyncSVG src='/images/avepdf-meet-us-visual.svg' />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="testimonials-block">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 px-4 px-md-5">
                            <div className="testimonials-wrapper">
                                <h3 className="title-fancy">
                                    <span className="title-fancy-icon"><AsyncSVG className="link-arrow" src='/images/title-icon.svg' /></span>
                                    {i10n._VIEW_LAYOUT_TILES_CUSTOMER_TELL_ABOUT_US}
                                </h3>
                                <Carousel>
                                    <div>
                                        <div className="testimonial-content">
                                            <div className="mb-2">
                                                <ul className="rating-stars">
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                </ul>
                                            </div>
                                            <p className="lead lead-small medium mb-1">Excellent and feature rich PDF toolbox</p>
                                            <p className="light">Pros: 1. All the features related to PDF files like conversion of PDF files, PDF files to other formats like Word document, merging PDF files together, OCR etc. 2. Easy to use interface and website with the type of pdf functions categorised/separated for easy navigation and execution. I also like that it has search tool for finding PDF tool. 3. Freeware service for so many tools.
                                                Cons: I did not find anything to dislike in this online PDF toolbox service.</p>
                                            <a tabindex="-1" className="medium" href="https://www.capterra.com/p/205562/AvePDF/#reviews" target="_blank">{i10n._VIEW_LAYOUT_TILES_READ_REVIEW}  <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="testimonial-content">
                                            <div className="mb-2">
                                                <ul className="rating-stars">
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                </ul>
                                            </div>
                                            <p className="lead lead-small medium mb-1">Extremely Useful</p>
                                            <p className="light">
                                                What do you like best?<br />
                                                I use this platform quite often whenever I have to make changes to a PDF file or things like that.<br />
                                                The fact that you don't need to install software and can use all features online like split, crop, merge, etc., makes this platform a must if you are dealing with a lot of documents in your day-to-day work. I highly recommend it.
                                            </p>
                                            <a tabindex="-1" className="medium" href="https://www.g2.com/products/avepdf/reviews#survey-response-5102397" target="_blank">{i10n._VIEW_LAYOUT_TILES_READ_REVIEW}  <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="testimonial-content">
                                            <div className="mb-2">
                                                <ul className="rating-stars">
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                    <li><FontAwesomeIcon className="" icon={faStar} /></li>
                                                </ul>
                                            </div>
                                            <p className="lead lead-small medium mb-1">make work easy and helpfull</p>
                                            <p className="light">
                                                What do you like best?<br />
                                                converting in the pdf file is very quick, and more types option we have to convert easy and time-
                                                saving</p>
                                            <a tabindex="-1" className="medium" href="https://www.g2.com/products/avepdf/reviews#survey-response-5046923" target="_blank">{i10n._VIEW_LAYOUT_TILES_READ_REVIEW}  <AsyncSVG className="link-arrow" src='/images/menu-icon-arrow.svg' /></a>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}