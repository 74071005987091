import { PureComponent } from 'react';
import i10n from '../../common/i10n';
import { CommonLayout } from '../../common/components/layouts/layout';
import clear from '../../common/components/clear';
import { connect } from 'react-redux';
import { Metadata } from '../../common/metadata';

const pageId = "F1D472F5-E417-42B1-978E-02D08B2CB3BB";

class CookiePolicyView extends PureComponent {

    render() {

        return (
            <CommonLayout
                pageId={pageId}>
                <Metadata />
                <div className="row">
                    <div className="col-md-12">
                        <div className="py-5">

                            <h1 className="title-header bold text-uppercase mb-3">{i10n._VIEW_HOME_COOKIES_POLICY_SUBTITLE}</h1>
                            <div className="white-space space-mini"></div>
                            <h2 className="title-exsmall normal" dangerouslySetInnerHTML={{ __html: i10n._VIEW_HOME_COOKIES_POLICY_TITLE_ONE}}></h2>
                            <hr />
                            <p>{i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_ONE}</p>
                            <p>{i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_TWO}</p>

                            <div className="white-space space-small"></div>

                            <h2 className="title-exsmall normal" dangerouslySetInnerHTML={{ __html: i10n._VIEW_HOME_COOKIES_POLICY_TITLE_TWO}}></h2>
                            <hr />
                            <p>{i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_THREE}</p>
                            <p>{i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_FOUR}</p>
                            <p dangerouslySetInnerHTML={{ __html: i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_FIVE}}></p>
                            <p>{i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_SIX}</p>

                            <div className="white-space space-small"></div>

                            <h2 className="title-exsmall normal">{i10n._VIEW_HOME_COOKIES_POLICY_TITLE_THREE}</h2>
                            <hr />
                            <p dangerouslySetInnerHTML={{ __html: i10n._VIEW_HOME_COOKIES_POLICY_PARAGRAPH_SEVEN}}></p>
                            <div className="white-space space-mini"></div>

                        </div>
                    </div>
                </div>
            </CommonLayout>);
    };
}
const mapState = ({ tool }) => {
    return {
        lang: tool.lang
    };
}
const CookiePolicy = clear(connect(mapState)(CookiePolicyView));
export default CookiePolicy;