import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <>
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_1}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_2}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_3}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_4}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_5}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1}
        {renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_6_PART_2, { type: 'AvePDFLink', href: tools.optimizeForWeb.url() })}
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_6_PART_3}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}
        {renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_7_PART_2, { type: 'link', href: 'https://www.gdpicture.com/blog/generate-zugferd-facturx-electronic-invoices-with-gdpicture/', removeSpaceAfter: true })}
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_1_DESC_7_PART_3}
    </>


export const renderSecondColumn = () =>
    <>
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_1}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_2}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_3}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_4}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
        &nbsp;({renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_2_DESC_5_PART_2, { type: 'link', href: 'https://www.iso.org/standard/64599.html', removeSpaceBefore: true, removeSpaceAfter: true })}).
    </>

export const renderThirdColumn = () =>
    <>
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_1}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_2}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_3}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}
        &nbsp;({renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_4_PART_2, { type: 'link', href: 'https://blog.avepdf.com/how-to-redact-pdf-online-remove-sensitive-personal-data/', removeSpaceBefore: true, removeSpaceAfter: true })}). <br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_5}<br />
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}
        {renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_6_PART_2, { type: 'AvePDFLink', href: tools.redactPdf.url(), removeSpaceAfter: true })}
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_6_PART_3}
        {renderBlock(i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_6_PART_4, { type: 'link', href: 'https://blog.avepdf.com/epic-pdf-redaction-fails-horror-story/' })}
        {i10n._VIEW_EDITPDFMETA_DIDYOUKNOW_COLUMN_3_DESC_6_PART_5}
    </>