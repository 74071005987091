import { getLocalizedLink } from '../../../common/components/tool';
import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import sitemap from '../../../common/sitemap';

export const renderFirstColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1} 
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://www.w3.org/People/Raggett/book4/ch02.html' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'https://www.w3.org/' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1, { type: 'AvePDFLink', href: getLocalizedLink(sitemap.VIEW_SVG_TO_PDF_ROUTE) })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}
        <br />
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1, { type: 'link', href: 'https://www.w3schools.com/html/html_links.asp' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_11_PART_1, { type: 'link', href: 'https://www.washington.edu/accesscomputing/webd2/student/unit1/module3/html_history.html#:~:text=The%20first%20version%20of%20HTML,HTML%20as%20an%20XML%20language' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_12_PART_1}
        <br />
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_13_PART_1}
        <br />
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_1_DESC_14_PART_1}
    </div>

export const renderSecondColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/PDF' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
        <br />
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://www.pdfa.org/' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
    </div>

    export const renderThirdColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/HTML5' })}
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}
        <br />
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}
        <br />
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1}&nbsp;
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}&nbsp;
        {i10n._VIEW_HTMLTOPDF_DIDYOUKNOW_COLUMN_3_DESC_7_PART_1}
    </div>