import axios from 'axios';
import sitemap from '../../common/sitemap'
import localStorageKeys from '../../constants/localStorageKeys';
import { GetUrl } from './utilities';

const LOGIN_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_LOGIN_ACTION_ROUTE ]);
const LOGOUT_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_LOGOUT_ACTION_ROUTE ]);
const REGISTER_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_REGISTER_ACTION_ROUTE ]);
const CONFIRM_EMAIL_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_CONFIRM_EMAIL_ACTION_ROUTE ]);
const FORGOT_PASSWORD_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_FORGOT_PASSWORD_ACTION_ROUTE ]);
const RESET_PASSWORD_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_RESET_PASSWORD_ACTION_ROUTE ]);
const EXTERNAL_LOGIN_CALLBACK_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_EXTERNAL_LOGIN_CALLBACK_ACTION_ROUTE ]);
const EXTERNAL_LOGIN_CONFIRM_CALLBACK_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_EXTERNAL_LOGIN_CALLBACK_CONFIRM_ACTION_ROUTE ]);
const EXTERNAL_LOGIN_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_EXTERNAL_LOGIN_ACTION_ROUTE ]);
const IS_LOGGED_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_LOGGED_ACTION_ROUTE ]);
const GET_USER_INFO_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_GET_USER_INFO ]);
const UPDATE_USER_INFO_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_UPDATE_USER_INFO ]);
const GET_SUBSCRIPTION_INFO_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_GET_SUBSCRIPTION_STATUS ]);
const CANCEL_SUBSCRIPTION_INFO_ENDPOINT = GetUrl([sitemap.ACCOUNT_CONTROLLER_ROUTE, sitemap.ACCOUNT_CANCEL_PLAN ]);

class _AuthenticationService {

    isLogged() {
        const user = localStorage.getItem(localStorageKeys.USER);

        return user ? true : false;
    }

    login(credentials) {
        return axios.post(LOGIN_ENDPOINT, credentials);
    }

    getExternalLogin(params) {
        window.location.href = EXTERNAL_LOGIN_ENDPOINT + "?provider=" + params.provider + "&returnUrl=" + params.returnUrl;
    }

    externalLoginCallback() {
        return axios.post(EXTERNAL_LOGIN_CALLBACK_ENDPOINT);
    }

    externalLoginConfirmCallback(params) {
        return axios.post(EXTERNAL_LOGIN_CONFIRM_CALLBACK_ENDPOINT, params);
    }

    logout(skipLogoffRequest) {
        localStorage.removeItem(localStorageKeys.USER);

        if (skipLogoffRequest === true){
            return Promise.resolve();
        }
        
        return axios.post(LOGOUT_ENDPOINT);
    }

    register(user) {
        return axios.post(REGISTER_ENDPOINT, user);
    }

    confirmEmail(params) {
        return axios.post(CONFIRM_EMAIL_ENDPOINT, params);
    }

    resetPassword(params) {
        return axios.post(RESET_PASSWORD_ENDPOINT, params);
    }

    forgotPassword(email) {
        return axios.post(FORGOT_PASSWORD_ENDPOINT, email);
    }

    isLogged() {
        return axios.get(IS_LOGGED_ENDPOINT);
    }
    
    getUserInfo() {
        return axios.get(GET_USER_INFO_ENDPOINT);
    }
    updateUserInfo(user) {
        return axios.post(UPDATE_USER_INFO_ENDPOINT, user);
    }

    getSubscriptionInfo() {
        return axios.get(GET_SUBSCRIPTION_INFO_ENDPOINT);
    }

    cancelSubscription(data) {
        return axios.post(CANCEL_SUBSCRIPTION_INFO_ENDPOINT, data);
    }
}

const AuthenticationService = new _AuthenticationService();
export default AuthenticationService;