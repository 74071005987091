import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <div>
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_1}
        {renderBlock(i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_2, { type: 'link', href: 'https://simple.wikipedia.org/wiki/Barcode#/media/File:Wikipedia_barcode_128.svg'})}
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_3} <br />
        
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_4} <br />

        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_5}
        {renderBlock(i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_6, { type: 'link', href: 'https://en.wikipedia.org/wiki/QR_code#/media/File:QR_code_for_mobile_English_Wikipedia.svg'})}
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_7} <br />

        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_8}
        {renderBlock(i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_9, { type: 'link', href: 'https://en.wikipedia.org/wiki/Data_Matrix#/media/File:Datamatrix.svg'})}
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_10} <br />

        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_11}
        {renderBlock(i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_12, { type: 'link', href: 'https://en.wikipedia.org/wiki/Aztec_Code#/media/File:Azteccodeexample.svg'})}
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_13} <br />

        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_14}
        {renderBlock(i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_15, { type: 'link', href: 'https://en.wikipedia.org/wiki/PDF417#/media/File:Wikipedia_PDF417.png'})}
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_1_DESC_16} <br />

    </div>

export const renderSecondColumn = () =>
    <div>
        
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_1}
        <br />
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_2}
        <br />
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_3}
        <br />
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_4}
        <br />
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_5}
        <br />
        {i10n._VIEW_BARCODERECO_DIDYOUKNOW_COLUMN_2_DESC_6}
        
    </div>
