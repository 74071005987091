import { PureComponent } from 'react';
import i10n from '../../common/i10n';
import { CommonLayout } from '../../common/components/layouts/layout';
import { Metadata } from '../../common/metadata';
import notFound from '../../assets/images/avepdf-404-error.png'
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { getLocalizedLink } from '../../common/components/tool';
import { connect } from 'react-redux';

export class NotFoundView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <CommonLayout className="vmiddle" pageId="">
                <Metadata />
                <div className="row" >
                    <div className="col-md-12">
                        <div className="center-text home-header-container">

                            <div className="row justify-content-md-center align-items-center not-found">
                                <div className="col-md-6 text-dark">
                                    <div className="not-found-header">404</div>
                                    <div className="not-found-title">
                                        {i10n._VIEW_404_NOT_FOUND_TITLE}
                                    </div>
                                    <div className="not-found-subtitle">
                                        {i10n._VIEW_404_NOT_FOUND_SUBTITLE}
                                    </div>
                                    <div>
                                        <Link to={getLocalizedLink('')}>
                                            <Button className="action-button not-found-action mb-4">{i10n._VIEW_404_GO_TO_HOME_PAGE}</Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-5 not-found-image">
                                    <img alt="Not found" src={notFound} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </CommonLayout>);
    }
};

const mapState = ({ tool }) => {
    return {
        language: tool.lang
    };
}

export const NotFound = connect(mapState)(NotFoundView);