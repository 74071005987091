import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import { detectLocale } from '../../../common/helpers/detectLocale';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <div>
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/JPEG'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_2}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_3, { type: 'AvePDFLink', href: tools.jpgToPdf.url })}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_4} <br />
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_5}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_6, { type: 'link', href: 'https://bellard.org/bpg/'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_7} <br />
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_9, { type: 'link', href: 'https://developers.google.com/speed/webp'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_1_DESC_1_PART_10}
    </div>

export const renderSecondColumn = () =>
    <div>
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_2, { type: 'link', href: 'https://www.gdpicture.com/'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_3}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_4, { type: 'link', href: 'https://www.docuvieware.com/'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_5}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_6, { type: 'AvePDFLink', href: tools.hyperCompress.url })}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_7}
        <br/>
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_8}
        <br/>
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_9}
        {renderBlock(i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_10, { type: 'link', href: 'https://www.digitaltrends.com/computing/why-people-still-love-ms-paint/'})}
        {i10n._VIEW_PDFTOJPG_DIDYOUKNOW_COLUMN_2_DESC_2_PART_11}
    </div>
