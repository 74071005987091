import i10n from './i10n/index';
import sitemap from './sitemap';
import { Helmet } from "react-helmet";
import { getStepsList } from '../common/components/howToUse'
import React from 'react'
import { withRouter } from 'react-router-dom';
import { betaKeys, isBetaEnabled } from './beta';

class MetadataComponent extends React.PureComponent {

    componentDidMount() {
        //We replace injected metadata with the helmet one
        const descriptions = document.querySelectorAll('link[rel="alternate"],link[rel="canonical"],meta[property^="og:"],meta[name^="twitter:"],meta[name="description"],meta[name="keywords"],script[type="application/ld+json"]');
        if (descriptions.length) {
            descriptions.forEach(node => node.remove());
        }
    }

    render() {
        var url = null;
        var currentLocation = window.location;
        var howToTitle = null;
        var howToLabels = [];
        let icon = null;
        //let { lang } = useParams();
        if (typeof this.props.tool === 'undefined' && currentLocation.pathname !== "") {
            let lastSegment = currentLocation.pathname.split('/')?.pop();
            //Ensure current path is not homepage
            if ((lastSegment !== null || lastSegment !== '') && !i10n.getAvailableLanguages().some(lang => lang.value === lastSegment)) {
                url = lastSegment;
            }
        }
        else {
            url = this.props.tool.url().split('/').pop();
            if (this.props.tool.howToUseLocalizationKeyPart && i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_HOW_TO_USE_TITLE`]) {
                howToTitle = i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_HOW_TO_USE_TITLE`];
                icon = `https://${window.AvePDFAppState.Host}${this.props.tool.ratingIcon}`;
                var labels = getStepsList(this.props.tool.howToUseLocalizationKeyPart);
                howToLabels = labels.map((label, i) => `
                                {
                                    "@type": "HowToStep",
                                    "name": "${i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_TITLE`]} ${i10n["_LAYOUT_STEP"]} ${i}",
                                    "text": "${label.replace(/"/g, '\\"')}",
                                    "url": "https://${window.AvePDFAppState.Host}${this.props.tool.url()}",
                                    "image": { "@type": "ImageObject", "url": "${icon}", "height": "240", "width": "280" }
                                }`
                );
            }
        }

        if (url === '') {
            url = null;
        }

        var meta = getMetadataForTool(url);
        let isRtl = window.AvePDFAppState.IsRtl;
        return (
            <Helmet>
                <html lang={i10n.currentLang} dir={isRtl ? "rtl" : "ltr"} />
                <title>{meta.Title}</title>
                <meta name="description" content={meta.Description} />
                <meta name="keywords" content={meta.Keywords} />
                <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />
                <meta property="og:title" content={meta.Title} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://${window.AvePDFAppState.Host + window.location.pathname}`} />
                <meta property="og:image" content={`${'https://' + window.AvePDFAppState.Host}${meta.Image}`} />
                <meta property="og:site_name" content={meta.Title} />
                <meta property="og:description" content={meta.Description} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@orpalis" />
                <meta name="twitter:creator" content="@orpalis" />
                <meta name="twitter:title" content={meta.Title} />
                <meta name="twitter:description" content={meta.Description} />
                <meta name="twitter:image:src" content={meta.Image} />
                {
                    i10n.getAvailableLanguages()
                        .map(lang => (
                            <link
                                rel="alternate"
                                hrefLang={lang.value}
                                href={`${window.location.origin}${lang.value == "en" ? "" : "/"}${lang.value == "en" ? "" : lang.value}${url !== null ? "/" : ""}${url ? url : ""}`}
                            />
                        ))
                }

                <script type="application/ld+json">
                    {`{
                        "@context": "http://schema.org",
                        "@type": "WebSite",
                        "name":  "${i10n["_VIEW_HOME_METADATA_TITLE"]}",
                        "description": "${i10n["_VIEW_HOME_METADATA_DESC"]}",
                        "url": "https://${window.AvePDFAppState.Host}",
                        "isAccessibleForFree": true
                }`}
                </script>

                <script type="application/ld+json">{`
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "Orpalis",
                "url": "https://www.orpalis.com/",
                "sameAs": [
                    "https://www.facebook.com/ORPALIS/",
                    "https://www.instagram.com/orpalis.france/",
                    "https://twitter.com/ORPALIS",
                    "https://www.linkedin.com/company/gdpicture-orpalis/"
                ]
            }
            `}</script>
                {
                    howToTitle !== null &&
                    <script type="application/ld+json">{`
                {
                    "@context": "http://schema.org",
                    "@type": "HowTo",
                    "image": { "@type": "ImageObject", "url": "${icon}", "height": "240", "width": "280" },
                    "name": "${howToTitle}", 
                    "description": "${i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_SUBTITLE`]}",
                    "totalTime": "PT1M",
                    "supply": [
                        {
                            "@type": "HowToSupply",
                            "name": "${i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_TITLE`]}"
                        }
                    ],
                    "tool": [
                      {
                        "@type": "HowToTool",
                        "name": "${i10n[`_VIEW_${this.props.tool.howToUseLocalizationKeyPart}_TITLE`]}"
                      }
                    ],
                    "step": [ 
                        ${howToLabels}
                    ]
                }`}
                    </script>
                }
            </Helmet>
        )
    }
};

//todo urgent: obtain metadata values from serialized server content.
const getMetadataForTool = (url) => {
    var metadata = {};
    switch (url) {
        case null:
            {
                metadata.Title = i10n["_VIEW_HOME_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_HOME_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_HOME_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_HOME_COOKIE_POLICY_ROUTE:
            {
                metadata.Title = i10n["_VIEW_COOKIE_POLICY_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_COOKIE_POLICY_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_COOKIE_POLICY_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_ACCOUNT_LOGIN_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_ACCOUNT_EXTERNAL_LOGIN_CALLBACK_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ACCOUNT_LOGIN_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_ACCOUNT_REGISTER_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ACCOUNT_REGISTER_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ACCOUNT_REGISTER_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ACCOUNT_REGISTER_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_ACCOUNT_FORGOT_PASSWORD_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ACCOUNT_FORGOT_PWD_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ACCOUNT_FORGOT_PWD_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ACCOUNT_FORGOT_PWD_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_COMPRESS_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_COMPRESSPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_COMPRESSPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_COMPRESSPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/01-compress-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_CONVERT_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_CONVERTTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CONVERTTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CONVERTTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/07-convert-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_CONVERT_TO_PDF_A_ROUTE:
            {
                metadata.Title = i10n["_VIEW_CONVERTTOPDFA_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CONVERTTOPDFA_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CONVERTTOPDFA_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/14-PDF-A-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_ESIGN_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ESIGNPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ESIGNPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ESIGNPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/08-eSign-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_PDFMRC_ROUTE:
            {
                metadata.Title = i10n["_VIEW_MRC_COMPRESSPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_MRC_COMPRESSPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_MRC_COMPRESSPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/02-hyper-compress-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_MERGE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_MERGEPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_MERGEPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_MERGEPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/05-merge-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_ORGANIZE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ORGANIZEPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ORGANIZEPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ORGANIZEPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/03-organize-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_LOCK_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_LOCKPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_LOCKPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_LOCKPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/10-protect-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_ROTATE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_ROTATEPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_ROTATEPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_ROTATEPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/04-rotate-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_SPLIT_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_SPLITPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_SPLITPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_SPLITPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/06-split-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_UNLOCK_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_UNLOCKPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_UNLOCKPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_UNLOCKPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/09-unlock-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_WORD_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_WORDTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_WORDTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_WORDTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/11-Word-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_EXCEL_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_EXCELTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_EXCELTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_EXCELTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/23-Excel-to-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_TIFF_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_TIFFTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_TIFFTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_TIFFTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/13-TIFF-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_PNG_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PNGTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PNGTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PNGTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/24-PNG-to-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_DXF_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_DXFTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_DXFTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_DXFTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/25-DXF-to-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_JPG_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_JPGTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_JPGTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_JPGTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/12-JPG-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_COMBINE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_COMBINE_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_COMBINE_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_COMBINE_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/15-combine-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_OCR_ROUTE:
            {
                metadata.Title = i10n["_VIEW_OCRPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_OCRPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_OCRPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/16-OCR-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_REMOVE_PDF_PAGES_ROUTE:
            {
                metadata.Title = i10n["_VIEW_DELETEPAGES_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_DELETEPAGES_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_DELETEPAGES_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/17-remove-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_TIFF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOTIFF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOTIFF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOTIFF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/18-PDF-TIFF-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_JPEG_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOJPG_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOJPG_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOJPG_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/19-PDF-JPG-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_FLATTEN_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_FLATTENPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_FLATTENPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_FLATTENPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/20-Flatten-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_CROP_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_CROPPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CROPPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CROPPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/21-Crop-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_RESIZE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_RESIZEPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_RESIZEPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_RESIZEPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/22-Resize-PDF-preview.png`;
                break;
            }
        case sitemap.VIEW_REMOVE_DOCUMENT_CONTENT_ROUTE:
            {
                metadata.Title = i10n["_VIEW_REMOVEDOCUMENTCONTENT_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_REMOVEDOCUMENTCONTENT_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_REMOVEDOCUMENTCONTENT_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/26-remove-document-content-preview.png`;
                break;
            }
        case sitemap.VIEW_SCAN_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_SCANNINGTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_SCANNINGTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_SCANNINGTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/27-scan-to-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_FILL_OUT_PDF_FORMS_ROUTE:
            {
                metadata.Title = i10n["_VIEW_FORMFIELDSFILLING_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_FORMFIELDSFILLING_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_FORMFIELDSFILLING_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/35-fill-out-pdf-form-preview.png`;
                break;
            }
        case sitemap.VIEW_REMOVE_INTERACTIVE_CONTENT_ROUTE:
            {
                metadata.Title = i10n["_VIEW_REMOVEINTERACTIVECONTENT_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_REMOVEINTERACTIVECONTENT_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_REMOVEINTERACTIVECONTENT_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/36-remove-interactive-content-preview.png`;
                break;
            }
        case sitemap.VIEW_DOCUMENT_VIEWER_ROUTE:
            {
                metadata.Title = i10n["_VIEW_DOCUMENTVIEWER_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_DOCUMENTVIEWER_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_DOCUMENTVIEWER_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/34-document-viewer-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_TEXT_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOTEXT_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOTEXT_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOTEXT_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/33-pdf-to-text-preview.png`;
                break;
            }
        case sitemap.VIEW_TEXT_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_TEXTTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_TEXTTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_TEXTTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/32-text-to-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_MARKUP_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_MARKUPPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_MARKUPPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_MARKUPPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/31-markup-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_DICOM_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_DICOMTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_DICOMTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_DICOMTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/30-dicom-to-pdf-preview.png`;
                break;
            }

        case sitemap.VIEW_REDACT_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_REDACTPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_REDACTPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_REDACTPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/38-redact-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_EXTRACT_PDF_PAGES_ROUTE:
            {
                metadata.Title = i10n["_VIEW_EXTRACTPDFPAGES_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_EXTRACTPDFPAGES_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_EXTRACTPDFPAGES_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/28-extract-pdf-pages-preview.png`;
                break;
            }
        case sitemap.VIEW_SVG_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_SVGTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_SVGTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_SVGTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/29-svg-to-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_REPAIR_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFREPAIR_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFREPAIR_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFREPAIR_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/37-repair-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_CONVERT_TO_ONE_PAGE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_CONVERTTOONEPAGE_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CONVERTTOONEPAGE_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CONVERTTOONEPAGE_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/39-convert-to-one-pdf-page-preview.png`;
                break;
            }
        case sitemap.VIEW_PPTX_TO_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PPTX_TO_PDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PPTX_TO_PDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PPTX_TO_PDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/59-ppt-to-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_EDIT_ROUTE:
            {
                metadata.Title = i10n["_VIEW_EDITPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_EDITPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_EDITPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/49-edit-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_CLEANUP_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_CLEANUPPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CLEANUPPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CLEANUPPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/50-clean-up-pdf-tools-preview.png`;
                break;
            }

        case sitemap.VIEW_REMOVE_TEXT_ROUTE:
            {
                metadata.Title = i10n["_VIEW_REMOVETEXT_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_REMOVETEXT_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_REMOVETEXT_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/40-remove-hidden-text-preview.png`;
                break;
            }

        case sitemap.VIEW_OPTIMIZE_PDF_FOR_WEB_ROUTE:
            {
                metadata.Title = i10n["_VIEW_LINEARIZEPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_LINEARIZEPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_LINEARIZEPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/51-optimize-for-web-tools-preview.png`;
                break;
            }

        case sitemap.VIEW_PDF_TO_PNG_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOPNG_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOPNG_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOPNG_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/52-pdf-to-png-tools-preview.png`;
                break;
            }

        case sitemap.VIEW_REVERSE_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_REVERSE_PDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_REVERSE_PDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_REVERSE_PDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/54-Reorder-PDF-tools-preview.png`;
                break;
            }

        case sitemap.VIEW_DOCUMENT_BARCODE_ROUTE:
            {
                metadata.Title = i10n["_VIEW_BARCODERECO_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_BARCODERECO_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_BARCODERECO_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/60-read-barcodes-preview.png`;
                break;
            }

        case sitemap.VIEW_PAGING_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PAGENUMBERS_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PAGENUMBERS_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PAGENUMBERS_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/55-Paging-PDF-tools-preview.png`;
                break;
            }
        case sitemap.VIEW_DOCUMENT_PDFA_VALIDATOR_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFA_VALIDATOR_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFA_VALIDATOR_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFA_VALIDATOR_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/56-pdfa-validator-preview.png`;
                break;
            }
        case sitemap.VIEW_CONVERT_TO_GRAYSCALE:
            {
                metadata.Title = i10n["_VIEW_CONVERTTOGRAYSCALE_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_CONVERTTOGRAYSCALE_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_CONVERTTOGRAYSCALE_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/57-convert-grayscale-preview.png`;
                break;
            }
        case sitemap.VIEW_EDIT_PDF_META_ROUTE:
            {
                metadata.Title = i10n["_VIEW_EDITPDFMETA_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_EDITPDFMETA_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_EDITPDFMETA_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/61-edit-pdf-metadata-preview.png`;
                break;
            }
        case sitemap.VIEW_HTML_TO_PDF:
            {
                metadata.Title = i10n["_VIEW_HTMLTOPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_HTMLTOPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_HTMLTOPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/58-html-to-pdf-preview.png`;
                break;
            }
        case sitemap.VIEW_WATERMARK_PDF_ROUTE:
            {
                metadata.Title = i10n["_VIEW_WATERMARKPDF_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_WATERMARKPDF_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_WATERMARKPDF_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/63-watermark-pdf-metadata-preview`;
                break;
            }
        case sitemap.VIEW_IMAGE_TO_EXCEL_ROUTE:
            {
                metadata.Title = i10n["_VIEW_IMAGETOEXCEL_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_IMAGETOEXCEL_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_IMAGETOEXCEL_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/image-to-excel-metadata-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_WORD_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOWORD_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOWORD_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOWORD_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/pdf-to-word-metadata-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_EXCEL_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PDFTOEXCEL_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PDFTOEXCEL_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PDFTOEXCEL_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/image-to-excel-metadata-preview.png`;
                break;
            }
        case sitemap.VIEW_PDF_TO_POWERPOINT_ROUTE:
        {
            metadata.Title = i10n["_VIEW_PDFTOPOWERPOINT_METADATA_TITLE"];
            metadata.Description = i10n["_VIEW_PDFTOPOWERPOINT_METADATA_DESC"];
            metadata.Keywords = i10n["_VIEW_PDFTOPOWERPOINT_METADATA_KEYS"];
            metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/pdf-to-powerpoint-metadata-preview.png`;
            break;
        }
        case sitemap.VIEW_HOME_COOKIE_POLICY_ROUTE:
            {
                metadata.Title = i10n["_VIEW_COOKIE_POLICY_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_COOKIE_POLICY_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_COOKIE_POLICY_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`; //we need to draw a cookie here.
                break;
            }
        case sitemap.VIEW_LIST_PLAN_ROUTE:
            {
                metadata.Title = i10n["_VIEW_PRICING_METADATA_TITLE"];
                metadata.Description = i10n["_VIEW_PRICING_METADATA_DESC"];
                metadata.Keywords = i10n["_VIEW_PRICING_METADATA_KEYS"];
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`; //we need to draw a cookie here.
                break;
            }
        case sitemap.VIEW_PROFILE_ROUTE :
            {
                metadata.Title = i10n._VIEW_PROFILE_TITLE;
                metadata.Image = `${process.env.PUBLIC_URL}/metadata/preview/00-AvePDF-home-preview.png`;
                break;
            }
        case sitemap.VIEW_LIST_PLAN_ROUTE:
            {
                metadata.Title = i10n._VIEW_PLAN_SUCCESS_PAYMENT_TITLE;
                break;
            }
            
        case sitemap.VIEW_PURCHASE_ISSUE_ROUTE:
            {
                metadata.Title = i10n._VIEW_PLAN_FAILED_PAYMENT_TITLE;
                break;
            }
        case sitemap.VIEW_ACCOUNT_UPDATE_PROFILE_ROUTE:
            {
                metadata.Title = i10n._VIEW_UPDATE_PROFILE_TITLE;
                break;
            }
        case sitemap.VIEW_ACCOUNT_MANAGE_SUBSCRIPTION:
            {
                metadata.Title = i10n._VIEW_PROFILE_MANAGE_SUBSCRIPTION;
                break;
            }

        case sitemap.VIEW_THANK_YOU_ROUTE:
            {
                metadata.Title = i10n._VIEW_ACCOUNT_EMAIL_CONFIRM_EMAIL;
                break;
            }
    }
    return metadata;
}

export const Metadata = withRouter(MetadataComponent);