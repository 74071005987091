import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import tools from '../../../constants/tools';

export const renderFirstColumn = () =>
    <div>
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_1}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_2}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_3}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_4}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_5}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_6}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}
        {renderBlock(i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_7_PART_2, { type: 'AvePDFLink', href: tools.hyperCompress.url })}
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_1_DESC_7_PART_3}
    </div>

export const renderSecondColumn = () =>
<div>
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
        {renderBlock(i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_1_PART_2, { type: 'link', href: 'https://en.wikipedia.org/wiki/Lazare_Carnot'})}
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_1_PART_3}&nbsp;
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_1_PART_4}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_2}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_3}
        <br />
        {i10n._VIEW_RESIZE_DIDYOUKNOW_COLUMN_2_DESC_4}
    </div>
