import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <>{i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_1_PART_1} <br /> {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_1_PART_2} <br /> {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_1_PART_3}</>


export const renderSecondColumn = () =>
<>
    {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_PART_1} <br /> 
    {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_PART_2} <br /> 
    {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_PART_3} <br /> 
    {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_PART_4} <br /> 
    {i10n._VIEW_PDFTOEXCEL_DIDYOUKNOW_COLUMN_2_PART_5}
</>
