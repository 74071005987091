import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumn = () =>
    <div>{i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}<br/>
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1}<br/>
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
    {renderBlock(i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Pantelegraph' })}
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/a-few-words-on-scanner-drivers/' })}
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
    {renderBlock(i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://www.twain.org/' })}
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
    {renderBlock(i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1, { type: 'link', href: 'https://www.orpalis.com/blog/twain-definition-usage/' })}
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}<br/>
    {i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_SCANTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1, { type: 'link', href: `https://${window.AvePDFAppState.Host}/en/pdf-ocr` })}</div>
