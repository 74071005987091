import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { onClear } from '../reducers/toolLayoutReducer';

const clear = ComposedComponent => {
	class hoc extends PureComponent {
		        
        componentWillUnmount() {
			this.clear();
			this.props.clear();
        }

        componentDidMount() {
            this.clear();
        }

        clear = () => {
            window.scrollTo(0, 0);
        }

		render() {
			return <ComposedComponent {...this.props} clear={this.clear} />
		}
	}

	return connect(
		() => ({}),
		mapDispatch
	)(hoc)
}

const mapDispatch = (dispatch) => {
    return {
        clear(step) {
			dispatch(onClear());
        }
    }
}

export default clear;
