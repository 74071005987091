import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';


export const renderFirstColumn = () =>
    <div>{i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Wilhelm_R%C3%B6ntgen' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Sonar' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1}</div>

export const renderSecondColumn = () =>
    <div>{i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_1_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Medical_imaging' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}<br/>
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1, { type: 'link', href: 'https://www.nibib.nih.gov/science-education/science-topics/nuclear-medicine' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1}</div>

export const renderThirdColumn = () =>
    <div>{i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1, { type: 'link', href: 'https://www.nema.org/pages/default.aspx' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_5_PART_1, { type: 'link', href: 'https://www.iso.org/standard/72941.html' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_6_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_7_PART_1}<br/>
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_8_PART_1}
    {renderBlock(i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_9_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/JPEG_2000' })}
    {i10n._VIEW_DICOMTOPDF_DIDYOUKNOW_COLUMN_3_DESC_10_PART_1}</div>