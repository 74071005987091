import PopupWindow from '../../../common/components/popupWindow';
import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';
import { useState } from 'react';

export const renderSecondColumnWithAddedLink = () =>
    <div>{i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_2_DESC_ALL} {renderBlock(i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_2_DESC_LINK.toLowerCase(), { type: 'link', href: 'https://www.pdfa.org/' })}</div>

export const renderThirdColumnWithAddedLink = (colorClass) => {
    return () => {
        const [ show, setShow ] = useState(false);
        const [ tabIdx, settabIdx ] = useState(false);
        return (
        <>
            <PopupWindow
                hidePopup={() => setShow(false)}
                showPopup={() => setShow(true)}
                setActiveTab={settabIdx}
                popupIsVisible={show}
                firstTabIsActive={tabIdx}
                fillClassName={colorClass}
            />
            <div>{i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1} {renderBlock(i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_DESC_LINK.toLowerCase(), { type: 'link', href: 'https://www.orpalis.com/blog/raster-vs-vector-graphics-images/' })}{i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1} 
            <a className="did-you-know-href" onClick={() => setShow(true)}> {i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_DESC_LINK}</a>{i10n._VIEW_CONVERTTOPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}</div>
        </>)
    }
}
