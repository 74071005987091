import tools from '../../constants/tools';
import { redirectionSteps } from '../../constants/steps';
import { betaKeys, isBetaEnabled } from '../beta';
import { saveItem } from '../helpers/localeStorage';
import localStorageKeys from '../../constants/localStorageKeys';

export const _initialState = {
    files: [],
    pendingRemoteFiles: [],
    inQueueFiles: [],
    processedContext: [],
    redirectionStep: 0,
    processedContextId: null,
    processedContextIdHadRedirected: false
};

const actionTypes = {
    FILE_UPLOADED: "FILE_UPLOADED",
    REMOTE_FILE_UPLOADED: "REMOTE_FILE_UPLOADED",
    REMOVE_FILE: "REMOVE_FILE",
    FILE_NEED_REDIRECTION: "FILE_NEED_REDIRECTION",
    FILES_WERE_PROCESSED: "FILES_WERE_PROCESSED",
    ON_LEAVE_TOOL: "ON_LEAVE_TOOL",
    PDF_INFO_RECEIVED: "PDF_INFO_RECEIVED",
    REORDER_FILES: "REORDER_FILES",
    SET_FILE_CONTEXT_ID: "SET_FILE_CONTEXT_ID",
    TOOL_REDIRECTION_INFO_SET: "TOOL_REDIRECTION_INFO_SET",
    REMOVE_ALL_FILE: "REMOVE_ALL_FILE",
    ADD_PENDING_REMOTE_FILE: "ADD_PENDING_REMOTE_FILE",
    RENAME_OUTPUT_FILE: "RENAME_OUTPUT_FILE",
    REMOVE_FILE_CONTEXT: "REMOVE_FILE_CONTEXT",
    SET_CONTEXT_DOWNLOAD: "SET_CONTEXT_DOWNLOAD"
};

const uploadFileReducer = (state = _initialState, action) => {
    let newState = { ...state };

    switch (action.type) {
        case actionTypes.FILE_UPLOADED:

            var fileInfo = {
                fileName: action.payload.fileName,
                uint8ArrayFileData: action.payload.uint8ArrayFileData,
                fileId: action.payload.fileId,
                pageCount: action.payload.pageCount,
                thumbnailsData: action.payload.thumbnailsData,
                isRemoteFile: false,
                fileSize: action.payload.fileSize
            };

            if (action.payload.replace) {
                newState.files = [fileInfo];
            } else {
                let uniqueFiles = newState.files.filter((f) => f.fileId != fileInfo.fileId);
                newState.files = [...uniqueFiles, fileInfo];
            }

            break;
        case actionTypes.REMOTE_FILE_UPLOADED:

            var fileInfo = {
                fileName: action.payload.fileName,
                fileId: action.payload.fileId,
                pageCount: action.payload.pageCount,
                thumbnailsData: action.payload.thumbnailsData,
                uint8ArrayFileData: action.payload.uint8ArrayFileData,
                isRemoteFile: true,
                fileSize: action.payload.fileSize
            };

            if (action.payload.replace) {
                newState.files = [fileInfo];
            } else {
                let uniqueFiles = newState.files.filter((f) => f.fileId != fileInfo.fileId);
                newState.files = [...uniqueFiles, fileInfo];
            }

            break;
        case actionTypes.ADD_PENDING_REMOTE_FILE:
            let remoteFileInfo = {
                url: action.payload.fileUrl,
                name: action.payload.name,
                pageId: action.payload.page
            };
            
            newState.pendingRemoteFiles = [...newState.pendingRemoteFiles, remoteFileInfo];
            break;
        case actionTypes.SET_FILE_CONTEXT_ID:
            newState.processedContextId = action.payload.id
            newState.files = [];
            newState.processedContextIdHadRedirected = false;
            newState.pendingRemoteFiles = [];
            break;

        case actionTypes.REMOVE_FILE:
            for (var index in newState.files) {
                if (newState.files[index].fileId === action.payload.fileId) {
                    newState.files.splice(index, 1);
                    break;
                }
            }
            break;

        case actionTypes.REMOVE_ALL_FILE:
            newState.files.splice(0, newState.files.length);
            newState.processedContextId = null;
            newState.pendingRemoteFiles = [];
            break;

        case actionTypes.FILE_NEED_REDIRECTION:
            let file = newState.files.find(file => file.fileId === action.payload.fileId);
            file.redirectionNeeded = true;
            break;

        case actionTypes.FILES_WERE_PROCESSED:
            for (var index in newState.files) {
                newState.files[index].processed = true;
            }

            if (newState.processedContext.some(pc => pc.id === newState.processedContextId)){
                var processedContext = newState.processedContext.find(pc => pc.id === newState.processedContextId);
                processedContext.outputSize = action.payload.outputSize;
                processedContext.outputFileName = action.payload.outputFileName;
                processedContext.toolId = action.payload.pageId;
                processedContext.downloaded = false;
                processedContext.expiresAt = getExpiration();
            } else {
                newState.processedContext = [{
                    id: newState.processedContextId,
                    outputSize: action.payload.outputSize,
                    outputFileName: action.payload.outputFileName,
                    toolId: action.payload.pageId,
                    downloaded: false,
                    expiresAt: getExpiration()
                }];
            }
            
            newState.outputFileSize = action.payload.outputSize;
            newState.outputFileName = action.payload.outputFileName;
    
            saveItem(localStorageKeys.PREVIOUS_CONTEXT, newState.processedContext);

            break;
        case actionTypes.RENAME_OUTPUT_FILE:
            newState.outputFileName = action.payload.outputFileName;
            var processedContext = newState.processedContext[0].outputFileName = action.payload.outputFileName;
            break;
        case actionTypes.ON_LEAVE_TOOL:
            if (!window.embedded && state.redirectionStep == redirectionSteps.beforeRedirection) {
                //To do: Need an optimisation
                var waitingFiles = [];
                var toProcessedFiles = [];
                for (let i = 0; i < newState.files.length; ++i) {
                    if (!newState.files[i].redirectionNeeded) {
                        waitingFiles.push(newState.files[i]);
                    } else {
                        toProcessedFiles.push(newState.files[i]);

                        let multipleFilesForRedirectionTargetedTool = Object.values(tools).find(t => t.id == state.redirectToWidget).uploadMultipleFiles;
                        if (!multipleFilesForRedirectionTargetedTool) {
                            waitingFiles = [...state.files];
                            waitingFiles = waitingFiles.filter((f) => f.fileId != newState.files[i].fileId);
                            break;
                        }
                    }
                }
                newState.inQueueFiles = waitingFiles;
                newState.files = toProcessedFiles;
            } else if (!window.embedded && state.redirectionStep == redirectionSteps.afterRedirection) {
                newState.files = [];
            } else {
                newState.files = [];
                newState.inQueueFiles = [];
            }
            break;

        case actionTypes.PDF_INFO_RECEIVED:
            for (var index in newState.files) {
                if (newState.files[index].fileId === action.payload.fileId) {
                    newState.files[index].numberOfPages = action.payload.numberOfPages;
                    break;
                }
            }
            break;

        case actionTypes.REORDER_FILES:
            var reordered = [];
            for (let i = 0; i < action.payload.orderedfileIds.length; ++i) {
                for (var index in newState.files) {
                    if (newState.files[index].fileId == action.payload.orderedfileIds[i]) {
                        reordered.push(newState.files[index]);
                        break;
                    }
                }
            }
            newState.files = newState.files.filter(f => !reordered.some(r => r.fileId === f.fileId)).concat(reordered);
            break;

        case actionTypes.TOOL_REDIRECTION_INFO_SET:
            let processedContextIdHadRedirected = false;
            if (newState.redirectionStep == redirectionSteps.afterRedirection && action.payload.redirectionStep === redirectionSteps.noRedirection){
                processedContextIdHadRedirected = true;
            }
            newState = {
                ...state,
                redirectionStep: action.payload.redirectionStep,
                fileToRedirect: action.payload.fileToRedirect,
                redirectToWidget: action.payload.redirectToWidget,
                mainTool: action.payload.mainTool,
                processedContextIdHadRedirected: processedContextIdHadRedirected
            }
            if (state.redirectionStep == redirectionSteps.inRedirection && action.payload.redirectionStep === redirectionSteps.afterRedirection){
                newState.files = newState.files.filter(file => file.fileId !== state.fileToRedirect);
            }

            break;
        case actionTypes.REMOVE_FILE_CONTEXT:
            newState.processedContext = newState.processedContext.filter(pc => pc.id !== action.payload.id);
            saveItem(localStorageKeys.PREVIOUS_CONTEXT, newState.processedContext);
            newState.redirectionStep = redirectionSteps.noRedirection;
            newState.fileToRedirect = null;
            newState.processedContextId = null;
            break;
        case actionTypes.SET_CONTEXT_DOWNLOAD:
            let ctx = newState.processedContext.find(pc => pc.id === action.payload.id);
            ctx.downloaded = true;
            ctx.expiresAt = getExpiration();

            newState.processedContext = [ ...newState.processedContext.filter(pc => pc.id === action.payload.id), ctx ];
            saveItem(localStorageKeys.PREVIOUS_CONTEXT, newState.processedContext);
            break;
        default:
            break;
    }
    
    return newState;
}

function getExpiration(){
    let now = new Date();
    now.setMilliseconds(now.getMilliseconds() + window.AvePDFAppState.MAX_PROCESSED_CONTENT_INACTIVITY);
    return now;
}

export const onFileUploaded = (data) => {
    return {
        type: actionTypes.FILE_UPLOADED,
        payload: {
            page: data.pageId,
            fileName: data.fileName,
            uint8ArrayFileData: data.uint8ArrayFileData,
            fileId: data.fileId,
            pageCount: data.pageCount,
            replace: data.replace,
            thumbnailsData: data.thumbnailsData,
            fileSize: data.fileSize
        }
    };
}

export const onAddPendingRemoteFile = (data) => {
    return {
        type: actionTypes.ADD_PENDING_REMOTE_FILE,
        payload: {
            page: data.pageId,
            name: data.name,
            fileUrl: data.fileUrl
        }
    };
}

export const onRemoteFileUploaded = (data) => {
    return {
        type: actionTypes.REMOTE_FILE_UPLOADED,
        payload: {
            page: data.pageId,
            fileName: data.fileName,
            uint8ArrayFileData: data.uint8ArrayFileData,
            fileId: data.fileId,
            pageCount: data.pageCount,
            replace: data.replace,
            thumbnailsData: data.thumbnailsData,
            fileSize: data.fileSize
        }
    };
}

export const onGetProcessedContextId = (data) => {
    return {
        type: actionTypes.SET_FILE_CONTEXT_ID,
        payload: {
            id: data.id,
            page: data.pageId
        }
    }
}

export const onRemoveProcessedContext = (id) => {
    return {
        type: actionTypes.REMOVE_FILE_CONTEXT,
        payload: {
            id: id
        }
    }
}

export const onRemoveFile = (data) => {
    return {
        type: actionTypes.REMOVE_FILE,
        payload: {
            page: data.pageId,
            fileId: data.fileId
        }
    };
}

export const onRemoveAllFiles = () => {
    return {
        type: actionTypes.REMOVE_ALL_FILE,
        payload: {}
    };
}

export const onFileNeedRedirection = (data) => {
        return {
            type: actionTypes.FILE_NEED_REDIRECTION,
            payload: {
                page: data.pageId,
                fileId: data.fileId
            }
        };
}

export const markFilesWereProcessed = ({pageId, outputSize, outputFileName}) => {
    return {
        type: actionTypes.FILES_WERE_PROCESSED,
        payload: {
            pageId: pageId,
            outputSize: outputSize,
            outputFileName: outputFileName
        }
    };
}

export const onLeaveTool = () => {
    return {
        type: actionTypes.ON_LEAVE_TOOL
    };
}

export const onPdfInfoReceived = (data) => {
    return {
        type: actionTypes.PDF_INFO_RECEIVED,
        payload: {
            page: data.pageId,
            fileId: data.fileId,
            numberOfPages: data.numberOfPages
        }
    };
}

export const onReorderUploadedFiles = (data) => {
    return {
        type: actionTypes.REORDER_FILES,
        payload: {
            page: data.pageId,
            orderedfileIds: data.orderedfileIds
        }
    };
}

export const onSetRedirectionInfo = (redirectionStep, fileToRedirect = null, redirectToWidget = null, mainTool = null) => {
    return {
        type: actionTypes.TOOL_REDIRECTION_INFO_SET,
        payload: {
            redirectionStep: redirectionStep,
            fileToRedirect: fileToRedirect,
            redirectToWidget: redirectToWidget,
            mainTool: mainTool // mainTool==null means it will be no redirection to the initial tool. Use for propositions at the end of a tool.
        }
    }
}

export const renameOutputFile = (outputFileName) => {
    return {
        type: actionTypes.RENAME_OUTPUT_FILE,
        payload:{
            outputFileName: outputFileName
        }
    }
}

export const onContextDownload = (id) => {
    return {
        type: actionTypes.SET_CONTEXT_DOWNLOAD,
        payload: {
            id: id
        }
    }
}

export default uploadFileReducer;