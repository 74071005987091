import { lookup } from 'mime-types';
import i10n from '../i10n';

const fileFormats = {
    all:  { ext: window.AvePDFAppState.ImportExtensions.join(',') },
    pdf: { label: "PDF", ext: ".pdf" },
    word: {label: "Word", ext: ".doc, .docx" },
    tiff: { label: "TIFF", ext: ".tif, .tiff" },
    txt: { get label() { return  i10n["_VIEW_UPLOAD_FILE_DROP_FILETYPE_TEXT"] }, ext: ".txt" },
    dxf: { label: "DXF", ext: ".dxf" },
    png: { label: "PNG", ext: ".png" },
    jpg: { label: "JPG", ext: ".jpe, .jpg, .jpeg" },
    dicom: { label: "DICOM", ext: ".dcm,.dicom"  }, //TODO: check correctness
    svg: { label: "SVG", ext: ".svg" },
    zip: { label: "zip", ext: ".zip" },
    excel: { label: "Excel", ext: ".xls, .xlsx" },
    powerpoint: { label: "PowerPoint", ext: ".ppt, .pptx" },
    html: { label: "HTML", ext: ".html, .htm, .mht, .mhtml", fileLimit: 31457280 /* 30 MB */ },
    imgBrowserSupported: { ext: ".jpeg, .jpg, .gif, .png, .bmp", get label() { return i10n["_VIEW_WATERMARK_IMG"] } },
}

const getDownloadSVGFileIconByExtension = (fileName) => {
    if (!fileName) {
        return '/images/12-white-avepdf-svg-download.svg';
    }

    var indexOfExtensionStart = fileName.lastIndexOf(".");

    if (indexOfExtensionStart < 0) {
        return '/images/12-white-avepdf-svg-download.svg';
    }

    var extension = fileName.substring(indexOfExtensionStart);

    switch (extension.toLowerCase()) {
        case ".pdf":
            return '/images/32-white-avepdf-svg-download-pdf.svg';

        case ".zip":
        case ".7z":
        case ".rar":
            return '/images/30-white-avepdf-svg-download-zip.svg';
        case ".txt": 
            return '/images/12-white-avepdf-svg-download.svg';
        case ".tiff": 
        case ".tif": 
        case ".png": 
            return '/images/31-white-avepdf-svg-download-bitmap.svg';
        default: {
            var mimeType = lookup(extension);
            return mimeType ? mimeType.toLocaleLowerCase().startsWith("image/") ? '/images/31-white-avepdf-svg-download-bitmap.svg' : '/images/12-white-avepdf-svg-download.svg' : '/images/12-white-avepdf-svg-download.svg';
        }
    }
}

function getFileSizeLimit(filename){
    var fileExt = filename.substring(filename.lastIndexOf('.'));

    const found = Object.keys(fileFormats).slice(1)
        .slice(1) //skip all format object
        .find(type => {
            if (fileFormats[type]?.ext?.split(',').some(ext => ext.trim() === fileExt)){
                return true;
            }
        });
    
    return found ?  fileFormats[found].fileLimit : null;
}

function getFileType(filename){
    var fileExt = filename.substring(filename.lastIndexOf('.'));

    const found = Object.keys(fileFormats).slice(1)
        .find(type => {
            if (fileFormats[type]?.ext?.split(',').some(ext => ext.trim() === fileExt)){
                return true;
            }
        });

    return found ?  fileFormats[found].label : null;
}

export {
    getDownloadSVGFileIconByExtension,
    fileFormats,
    getFileSizeLimit,
    getFileType
};