import { renderBlock } from '../../../common/helpers/didYouKnowHelper';
import i10n from '../../../common/i10n';

export const renderFirstColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_1_PART_1} 
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Cryptography' })}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_4_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Caesar_cipher', removeSpaceAfter: true })}{i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_5_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_6_PART_1}
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_7_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Enigma_machine' })}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_8_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_9_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_10_PART_1}
        {/* This link may raise issue in SemRush Audit */}
        {/* {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_11_PART_1, { type: 'link', removeSpaceAfter: true, href: 'https://edps.europa.eu/sites/default/files/publication/07-08-2020_techdispatch_quantum_computing_en_0.pdf' })} */}
        &nbsp;{i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_11_PART_1}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_1_DESC_12_PART_1}
    </div>

export const renderSecondColumnWithAddedLink = () =>
    <div>
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_2_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/RC4' })}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_4_PART_1, { type: 'link', href: 'https://en.wikipedia.org/wiki/Advanced_Encryption_Standard' })}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_5_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_6_PART_1}
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_7_PART_1, { type: 'link', href: 'https://threatpost.com/attack-exploits-weakness-rc4-cipher-decrypt-user-sessions-031413/77628/' })}
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_8_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_2_DESC_9_PART_1}
    </div>

    export const renderThirdColumnWithAddedLink = () =>
    <div>
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_1_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_2_PART_1}
        <br />
        {i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_3_PART_1}
        {renderBlock(i10n._VIEW_LOCKPDF_DIDYOUKNOW_COLUMN_3_DESC_4_PART_1, { type: 'link', href: 'https://searchsecurity.techtarget.com/definition/dictionary-attack' })}
    </div>