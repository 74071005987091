import { PureComponent } from 'react';
import i10n from '../../../common/i10n';
import { connect } from 'react-redux';
import AuthLayoutContainer, { authTabs } from '../../../common/components/layouts/authLayout';
import clear from '../../../common/components/clear';
import { Checkbox, Input } from 'antd';
import { Form } from '@ant-design/compatible';
import AuthService from '../../../common/services/authService';
import { getLocalizedLink } from '../../../common/components/tool';
import sitemap from '../../../common/sitemap'
import { AsyncSVG } from '../../../common/components/AsyncSVG';
import { Redirect } from 'react-router-dom';
import { UserHelper } from '../../../common/helpers/userHelper';

const pageId = "26FDBFB0-C026-40F2-B56F-1570DA1E3280";

class UpdateProfileForm extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmPasswordDirty: false,
            initialUserInfo: UserHelper.GetUser(),
            updatePassword: false
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const updateProfileForm = {
                    Email: values.email.trim(),
                    Firstname: values.firstName,
                    Lastname: values.lastName,
                    CurrentPassword: this.state.updatePassword ? values.currentPassword : null,
                    Password: this.state.updatePassword ? values.password : null,
                    ConfirmPassword: this.state.updatePassword ? values.confirmPassword : null
                };

                if (this.props.onStartRequest) {
                    this.props.onStartRequest();
                }

                AuthService.updateUserInfo(updateProfileForm).then(response => {
                    if (this.props.onSuccess && response.data.success) {
                        UserHelper.StoreUser(response.data.userInfo);
                        this.props.onSuccess();
                    }
                    else if (this.props.onError && !response.data.success) {
                        this.props.onError(response.data.errorMessages);
                    }

                }, error => {
                    if (this.props.onError) {
                        this.props.onError([error.message]);
                    }
                });
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmPasswordDirty: this.state.confirmPasswordDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        if (!this.state.updatePassword) {
            callback();
        }
        const { form } = this.props;

        form.validateFields(['currentPassword'], { force: true });

        if (value && value !== form.getFieldValue('password')) {
            callback(i10n._VALIDATION_ERROR_PASSWORD_COMPARE);
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        if (!this.state.updatePassword) {
            callback();
        }
        const { form } = this.props;
        if (value && this.state.confirmPasswordDirty) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    };

    showPlaceholder = fieldName => {
        const currentValue = this.props.form.getFieldValue(fieldName);

        return currentValue !== '';
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        window.f = this.props.form;
        return (
            <Form className="signup-form my-4">
                <Form.Item className="mb-4">
                    <span style={this.showPlaceholder("firstName") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_ACCOUNT_REGISTER_FIRSTNAME}</span>
                    {
                        getFieldDecorator('firstName', {
                            initialValue: this.state.initialUserInfo.firstName,
                            rules: [
                                { transform: (value) => value.trim() },
                                { required: true, message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_ACCOUNT_REGISTER_FIRSTNAME) }
                            ],
                        })
                            (
                                <Input
                                    autoFocus={true}
                                    tabIndex="8"
                                    placeholder={i10n._VIEW_ACCOUNT_REGISTER_FIRSTNAME}
                                    onPressEnter={this.handleSubmit}
                                    name="firstName"
                                    prefix={
                                        <AsyncSVG className="login-icon" src="/images/login-signup-icons-my-profile.svg" />
                                    }
                                />
                            )
                    }
                </Form.Item>
                <Form.Item className="mb-4">
                    <span style={this.showPlaceholder("lastName") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_ACCOUNT_REGISTER_LASTNAME}</span>
                    {
                        getFieldDecorator('lastName', {
                            initialValue: this.state.initialUserInfo.lastName,
                            rules: [
                                { transform: (value) => value.trim() },
                                { required: true, message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_ACCOUNT_REGISTER_LASTNAME) }
                            ],
                        })
                            (
                                <Input
                                    autoFocus={true}
                                    tabIndex="9"
                                    placeholder={i10n._VIEW_ACCOUNT_REGISTER_LASTNAME}
                                    onPressEnter={this.handleSubmit}
                                    name="lastName"
                                    prefix={
                                        <AsyncSVG className="login-icon" src="/images/login-signup-icons-my-profile.svg" />
                                    }
                                />
                            )
                    }
                </Form.Item>
                <Form.Item className="mb-4">
                    <span style={this.showPlaceholder("email") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_ACCOUNT_REGISTER_EMAIL}</span>
                    {
                        getFieldDecorator('email', {
                            initialValue: this.state.initialUserInfo.email,
                            rules: [
                                { transform: (value) => value.trim() },
                                { required: true, message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_ACCOUNT_REGISTER_EMAIL) },
                                { type: "email", message: i10n._VALIDATION_ERROR_EMAIL }
                            ],
                        })
                            (
                                <Input
                                    autoFocus={true}
                                    tabIndex="1"
                                    placeholder={i10n._VIEW_ACCOUNT_REGISTER_EMAIL}
                                    onPressEnter={this.handleSubmit}
                                    name="email"
                                    prefix={
                                        <AsyncSVG className="login-icon" src="/images/login-signup-icons-email.svg" />
                                    }
                                />
                            )
                    }
                </Form.Item>
                {
                    this.state.initialUserInfo.provider === null &&
                    <Form.Item className="remember-forgot-block mb-4">
                        <div className="row">
                            <div className="col-sm-6">
                                {
                                    getFieldDecorator('updatePassword', {
                                        valuePropName: 'unchecked'
                                    })(<Checkbox
                                        className="remember-checkbox"
                                        name="updatePassword"
                                        tabIndex="5"
                                        value={this.state.updatePassword}
                                        onChange={() => this.setState({ updatePassword: !this.state.updatePassword })}
                                    >
                                        {i10n._VIEW_UPDATE_PROFILE_UPDATE_PASSWORD}
                                    </Checkbox>)
                                }
                            </div>
                        </div>
                    </Form.Item>
                }
                {
                    this.state.updatePassword &&
                    <>
                        <Form.Item className="mb-4">
                            <span style={this.props.form.getFieldValue("currentPassword") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_ACCOUNT_REGISTER_PASSWORD}</span>
                            {
                                getFieldDecorator('currentPassword', {
                                    initialValue: this.props.form.getFieldValue("currentPassword"),
                                    rules: [
                                        { required: this.state.updatePassword, message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_ACCOUNT_REGISTER_PASSWORD) },
                                        { validator: this.validateCurrentPassword }
                                    ],
                                })
                                    (
                                        <Input.Password
                                            placeholder={i10n._VIEW_ACCOUNT_REGISTER_PASSWORD}
                                            onPressEnter={this.handleSubmit}
                                            name="currentPassword"
                                            tabIndex="2"
                                            prefix={
                                                <AsyncSVG className="login-icon" src="/images/login-signup-icons-password.svg" />
                                            }
                                        />
                                    )
                            }
                        </Form.Item>
                        <Form.Item className="mb-4">
                            <span style={this.props.form.getFieldValue("password") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_UPDATE_PROFILE_NEW_PASSWORD}</span>
                            {
                                getFieldDecorator('password', {
                                    initialValue: this.props.form.getFieldValue("password"),
                                    rules: [
                                        { required: this.props.form.getFieldValue("currentPassword") !== '', message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_UPDATE_PROFILE_NEW_PASSWORD) },
                                        { validator: this.validateToNextPassword }
                                    ],
                                })
                                    (
                                        <Input.Password
                                            placeholder={i10n._VIEW_UPDATE_PROFILE_NEW_PASSWORD}
                                            onPressEnter={this.handleSubmit}
                                            name="password"
                                            tabIndex="3"
                                            prefix={
                                                <AsyncSVG className="login-icon" src="/images/login-signup-icons-password.svg" />
                                            }
                                        />
                                    )
                            }
                        </Form.Item>
                        <Form.Item className="mb-4">
                            <span style={this.props.form.getFieldValue("confirmPassword") ? { visibility: 'visible', opacity: 1 } : {}} className="input-corner-label">{i10n._VIEW_ACCOUNT_REGISTER_CONFIRM_PASSWORD}</span>
                            {
                                getFieldDecorator('confirmPassword', {
                                    initialValue: this.props.form.getFieldValue("confirmPassword"),
                                    rules: [
                                        { required: this.props.form.getFieldValue("currentPassword") !== '', message: i10n._VALIDATION_ERROR_REQUIRED.replace("{0}", i10n._VIEW_ACCOUNT_REGISTER_CONFIRM_PASSWORD) },
                                        { validator: this.compareToFirstPassword }
                                    ],
                                })
                                    (
                                        <Input.Password
                                            placeholder={i10n._VIEW_ACCOUNT_REGISTER_CONFIRM_PASSWORD}
                                            onPressEnter={this.handleSubmit}
                                            name="confirmPassword"
                                            tabIndex="4"
                                            onBlur={this.handleConfirmBlur}
                                            prefix={
                                                <AsyncSVG className="login-icon" src="/images/login-signup-icons-password.svg" />
                                            }
                                        />
                                    )
                            }
                        </Form.Item>
                    </>
                }
            </Form>
        );
    }
}

const WrappedUpdateProfileForm = Form.create({ name: 'update' })(UpdateProfileForm);

class UpdateProfile extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            updating: true,
            updated: false,
            loading: false,
            errorMessages: []
        };
    }

    startRequest = () => {
        this.setState({
            loading: true
        });
    }

    success = () => {
        this.setState({
            updating: false,
            updated: true,
            loading: false
        });
    }

    error = (errorMessages) => {
        this.setState({
            loading: false,
            errorMessages: errorMessages
        });
    }

    render() {
        if (this.state.updated) {
            return <Redirect to={getLocalizedLink(sitemap.VIEW_PROFILE_ROUTE)} />
        }
        return (
            <AuthLayoutContainer
                pageId={pageId}
                actionButtonText={i10n._VIEW_PROFILE_UPDATE_PROFILE}
                onActionButtonClick={(e) => this.form.handleSubmit(e)}
                errorMessages={this.state.errorMessages}
                loading={this.state.loading}
                cancelButtonText={i10n._VIEW_PROFILE_UPDATE_PROFILE_BUTTON_CANCEL}
                onCancelRedirect={getLocalizedLink(sitemap.VIEW_PROFILE_ROUTE)}>
                <WrappedUpdateProfileForm
                    onStartRequest={this.startRequest}
                    onSuccess={this.success}
                    onError={this.error}
                    wrappedComponentRef={form => this.form = form} />
            </AuthLayoutContainer>
        );
    }
};

const mapState = ({ tool }) => {
    return {
        lang: tool.lang
    };
}

const UpdateProfileContainer = clear(connect(mapState)(UpdateProfile));
export default UpdateProfileContainer;